import { Fancybox } from "@fancyapps/ui";

import $ from 'jquery'
import 'slick-carousel'

Fancybox.bind("[data-fancybox]")

$(document).ready( initMainSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initMainSlider)
}

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      infinite: false,
      arrows: true,
      dots: true,
      slidesToShow: 4,
      slidesToScroll: 1,

      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }
}